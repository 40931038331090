<template>
  <div class="checkout-btn-wrapper">
    <slot name="buttonContent" />
    <CustomButton
      :disabled="
        rootStore?.siteUnderMaintenance ||
        cartStore.cartActionInProgress ||
        cartStore.checkoutInitiatied ||
        !cartStore.cart
      "
      aria-label="Checkout Current Cart"
      style-color="black"
      @click-button="checkout()"
    >
      <Lock2Line />{{
        cartStore.checkoutInitiatied ? 'Checking Out...' : useTranslate('CartSlideout.checkout') || 'Checkout'
      }}
    </CustomButton>
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~/store/cart';
import { useRootStore } from '~/store';
import { mapBCCartItemsToGtmCartItems, baseEcommerceEventStructure, GtmEventType } from '~/util/analytics';
import { setCookie } from '~/util/storage';

const cartStore = useCartStore();
const rootStore = useRootStore();
const gtm = useGtm();

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function checkout(): Promise<void> {
  const config = useRuntimeConfig();
  const domain = config?.public?.cookieDomain;

  cartStore.setCheckoutInitiatied(true);

  const gtmItems = mapBCCartItemsToGtmCartItems(cartStore.filteredProducts);
  const gtmEvent = baseEcommerceEventStructure({ eventType: GtmEventType.beginCheckout, items: gtmItems });
  gtm?.trackEvent(gtmEvent);

  const metaPixel = useMetaPixel();
  metaPixel?.trackEvent({
    event: 'InitiateCheckout',
    eventItems: gtmItems,
  });

  setCookie('checkout_referrer', location.origin, 30, true, domain);
  await useCartCheckout();
  await sleep(2000); // can't await url navigation, so manually wait for 2s (for now)
  cartStore.setCheckoutInitiatied(false);
}
</script>
